import React, { type FC } from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { CardContent, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import { convertNumberToShortForm } from 'src/Util'

const DetailedData = ({ title, data, description, color, info = null }) => {
  return (
    <Stack spacing={2}>
      <p className={'text-center text-xs font-normal not-italic'}>{title}</p>
      <p
        className={'text-center text-3xl not-italic leading-8'}
        data-testid={`nps-detailed-data-${title}-${data}`}
      >
        {data}
      </p>

      <div className="flex items-center justify-center">
        <p className="mr-2 text-center text-xs font-normal not-italic">
          {description}
        </p>
        {info && (
          <Tooltip title={info} enterDelay={200} enterNextDelay={200}>
            <InformationCircleIcon
              className="h-4 w-4 text-blue-500"
              data-testid="information-circle-icon"
            />
          </Tooltip>
        )}
      </div>

      <span className={`h-1.5 w-20 bg-${color}-500 rounded-full`}></span>
    </Stack>
  )
}

const calculatePercentage = (count: number, responses: number) => {
  const percentage = Math.round((count / responses) * 100)

  if (isNaN(percentage)) {
    return '-'
  }
  if (!isFinite(percentage)) {
    return '-'
  }
  return `${percentage}%`
}

type ScoreDistributionProps = {
  responses?: number
  promoterCount?: number
  neutralCount?: number
  detractorCount?: number
  surveySentCount?: number
  userMode: boolean
  filtersUsed: number[]
}

const NpsScoreDistribution: FC<ScoreDistributionProps> = ({
  responses,
  promoterCount,
  neutralCount,
  detractorCount,
  surveySentCount,
  userMode,
  filtersUsed,
}) => {
  return (
    <Card variant="outlined" sx={{ height: '250px' }}>
      <CardHeader
        title={
          <h2 className={'text-sm font-semibold leading-5'}>
            Latest Score Distribution
          </h2>
        }
      />
      <CardContent className={'flex justify-around overflow-y-hidden !px-6'}>
        {!userMode ? (
          <DetailedData
            title={'Responses'}
            data={calculatePercentage(responses, surveySentCount)}
            description={
              <Tooltip title={`${responses} / ${surveySentCount}`}>
                <span>
                  {convertNumberToShortForm(responses)} /{' '}
                  {convertNumberToShortForm(surveySentCount)}
                </span>
              </Tooltip>
            }
            color={'blue'}
            info={
              filtersUsed
                ? `The total number of surveys sent for all campaigns selected is ${convertNumberToShortForm(
                    surveySentCount,
                  )}. It can not be filtered as individual sent surveys are not tracked.`
                : null
            }
          />
        ) : (
          <DetailedData
            title={'Responses'}
            data={responses}
            color={'blue'}
            description={'Total'}
          />
        )}
        <DetailedData
          title={'Promoters'}
          data={calculatePercentage(promoterCount, responses)}
          description={promoterCount}
          color={'green'}
        />
        <DetailedData
          title={'Passives'}
          data={calculatePercentage(neutralCount, responses)}
          description={neutralCount}
          color={'yellow'}
        />
        <DetailedData
          title={'Detractor'}
          data={calculatePercentage(detractorCount, responses)}
          description={detractorCount}
          color={'red'}
        />
      </CardContent>
    </Card>
  )
}

export default NpsScoreDistribution
