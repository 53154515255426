import React, { ReactNode } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button, { CustomButtonProps } from '../Button/Button'

interface Props {
  buttonIcon?: React.ReactNode
  buttonTitle: string
  buttonProps: CustomButtonProps
  GATrackerCategory?: string
  children?: ReactNode
}

const CustomDropdown: React.FC<Props> = ({
  buttonIcon = <ChevronDownIcon />,
  children,
  buttonTitle,
  buttonProps,
  GATrackerCategory,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const { trackEvent } = useAnalytics()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    if (GATrackerCategory)
      trackEvent(GATrackerCategory, `click ${buttonTitle} button`)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="Custom-Dropdown-Button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={buttonIcon}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
      <Menu
        id="cardContextMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={0}
        elevation={3}
        sx={{
          marginTop: '6px',
          padding: 0,
          borderRadius: '10px',
          '& ul': {
            padding: 0,
            '& li': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
        }}
      >
        {React.Children.map(children, (child, index) => (
          <MenuItem disableRipple key={index}>
            {child}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CustomDropdown
