import React, { type FC } from 'react'

import {
  CardContent,
  Chip,
  Card,
  CardHeader,
  Tooltip as MuiToolTip,
} from '@mui/material'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import useAnalytics from 'src/lib/hooks/useAnalytics'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
}

type Props = {
  promoterCount?: number
  neutralCount?: number
  detractorCount?: number
  npsScore?: number
  isAggregated?: boolean
}

const NpsGuage: FC<Props> = ({
  promoterCount,
  neutralCount,
  detractorCount,
  npsScore,
  isAggregated,
}) => {
  const data = {
    labels: ['Detractors', 'Passives', 'Promoters'],
    datasets: [
      {
        label: 'Results',
        data: [detractorCount, neutralCount, promoterCount],
        backgroundColor: ['#ef4444', '#eab308', '#22c55e'],
        circumference: 180,
        rotation: 270,
        borderWidth: 1,
        cutout: '80%',
        hoverOffset: 1,
      },
    ],
  }

  const plugins = [
    {
      id: 'textCenter',
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx
        ctx.restore()
        const fontSize = (height / 50).toFixed(2)
        ctx.font = 'bolder ' + fontSize + 'em  sans-serif'
        ctx.textBaseline = 'top'
        const text = `${npsScore}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2
        ctx.fillText(text, textX, textY)
        ctx.save()
      },
    },
  ]

  const { trackEvent } = useAnalytics()
  return (
    <Card
      variant="outlined"
      sx={{ height: '250px' }}
      onMouseEnter={() => {
        trackEvent('NPS', 'NPS Score donut hover')
      }}
    >
      <CardHeader
        title={
          <h2 className={'text-sm font-semibold leading-5'}>Latest Score</h2>
        }
        action={
          isAggregated && (
            <MuiToolTip
              placement="top"
              arrow
              title="Scores are combined with the latest campaigns across measurments"
            >
              <Chip size={'small'} label={'Aggregated'} />
            </MuiToolTip>
          )
        }
      />
      <CardContent
        className={'flex justify-center overflow-x-hidden overflow-y-hidden'}
      >
        <Doughnut
          id="chartCanvas"
          data={data}
          options={options}
          plugins={plugins}
          key={npsScore}
        />
      </CardContent>
    </Card>
  )
}

export default NpsGuage
