import React, { useEffect, useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import Divider from 'src/components/Library/Divider/Divider'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import NpsInsightDataCell from 'src/components/Nps/NpsInsightDataCell'
import NpsInsightsFiltersCell from 'src/components/Nps/NpsInsightsFiltersCell'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const NpsInsightsPage = ({ measurementId }) => {
  useEffect(() => {
    if (measurementId) {
      setMeasurementIds([measurementId])
    }
  }, [])
  const [subjectIds, setSubjectIds] = useState<[number] | null>(null)
  const [assessorIds, setAssessorIds] = useState<[number] | null>(null)
  const [measurementIds, setMeasurementIds] = useState<[number] | null>(null)
  const [campaignIds, setCampaignIds] = useState<[number] | null>(null)
  return (
    <>
      <Metadata title="Nps Insights" description="Nps Insights page" />
      <div className={'h-screen'}>
        <PageHeader
          title={'Net Promoter Score'}
          parentDataTestId="nps-insights-page-header"
        />
        <FeatureToggle
          feature={FLAG_FEATURES.NPS_INSIGHTS}
          InactiveComponent={<FeatureDenied />}
        >
          <div className="flex flex-1 justify-center h-[calc(100vh-5rem)]">
            <div className="px-8 pt-8 overflow-y-auto w-full h-full mb-2">
              <NpsInsightsFiltersCell
                setMeasurementIds={setMeasurementIds}
                setAssessorIds={setAssessorIds}
                setSubjectIds={setSubjectIds}
                setCampaignIds={setCampaignIds}
              />
              <Divider className={'my-4 p-0'} />
              <NpsInsightDataCell
                measurementIds={measurementIds}
                subjectIds={subjectIds}
                assessorIds={assessorIds}
                campaignIds={campaignIds}
              />
            </div>
          </div>
        </FeatureToggle>
      </div>
    </>
  )
}

export default NpsInsightsPage
