import { groupByWithFn } from 'api/src/common/collections'
import { calculateNpsScore } from 'api/src/common/utils'
import type {
  NpsInsightDataQuery,
  NpsInsightDataQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import NpsInsights from 'src/components/Nps/NpsInsights/NpsInsights'

export const QUERY = gql`
  query NpsInsightDataQuery(
    $subjectIds: [Int!]
    $assessorIds: [Int!]
    $measurementIds: [Int!]
    $campaignIds: [Int!]
  ) {
    npsCampaigns: getNpsCampaignsByFilters(
      measurementIds: $measurementIds
      subjectIds: $subjectIds
      assessorIds: $assessorIds
      campaignIds: $campaignIds
    ) {
      id
      name
      campaignType
      measurementId
      campaignDate
      surveySentCount
      scoreTotals(subjectIds: $subjectIds, assessorIds: $assessorIds) {
        responses
        detractorCount
        neutralCount
        promoterCount
        npsScore
      }
      assessors(subjectIds: $subjectIds, assessorIds: $assessorIds) {
        assessorId
        npsScore
      }
      latestCampaign {
        id
        name
        surveySentCount
        scoreTotals(subjectIds: $subjectIds, assessorIds: $assessorIds) {
          responses
          detractorCount
          neutralCount
          promoterCount
          npsScore
        }
      }
    }
    subjects: npsSubjects {
      id
      name
      membership {
        id
        user {
          id
          name
          avatarUrl
        }
      }
    }
    assessors: npsAssessors {
      id
      name
    }
    measurements: npsMeasurements {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <EmptyData
    title={'No NPS Insights Found'}
    description={
      'There are no insights for the filters you have selected. Try changing your filters to find Insights'
    }
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

interface NpsInsightDataCellProps
  extends CellSuccessProps<NpsInsightDataQuery, NpsInsightDataQueryVariables> {
  userMode?: boolean
  assessorIds: number[]
  subjectIds: number[]
}

export const Success = ({
  npsCampaigns,
  subjects,
  assessors,
  measurements,
  assessorIds,
  subjectIds,
  userMode = false,
}: NpsInsightDataCellProps) => {
  // TODO: fix the state here, it's not set inside useState or updated via useEffect

  let latestCampaign =
    null as NpsInsightDataQuery['npsCampaigns'][0]['latestCampaign']
  const ids = npsCampaigns.map((campaign) => campaign.measurementId)

  if (ids?.length === 1 && !userMode) {
    latestCampaign = npsCampaigns?.[0]?.latestCampaign
  } else if (userMode) {
    latestCampaign = npsCampaigns?.[0]?.latestCampaign
  } else {
    //   find the 3 highest subjects in all latest campaigns
    const groupedCampaigns = groupByWithFn(npsCampaigns, (x) => x.measurementId)
    const groupedCampaignsValues = Array.from(groupedCampaigns.values())
    const campaignsList = groupedCampaignsValues.map((measurement) => {
      // Q: Shouldn't this be getting the NpsCampaign with the highest npsScore?
      // This is hard-coded to the first campaign in the list
      return measurement?.[0]?.latestCampaign
    })

    if (campaignsList.length > 0) {
      // aggregate the latest campaigns
      const aggregatedCampaigns = campaignsList
        .filter(Boolean)
        .reduce((acc, campaign) => {
          const result = {
            ...acc,
            surveySentCount: acc.surveySentCount + campaign.surveySentCount,
            scoreTotals: {
              ...acc.scoreTotals,
              responses:
                acc.scoreTotals.responses + campaign.scoreTotals.responses,
              detractorCount:
                acc.scoreTotals.detractorCount +
                campaign.scoreTotals.detractorCount,
              neutralCount:
                acc.scoreTotals.neutralCount +
                campaign.scoreTotals.neutralCount,
              promoterCount:
                acc.scoreTotals.promoterCount +
                campaign.scoreTotals.promoterCount,
              npsScore: calculateNpsScore(
                acc.scoreTotals.promoterCount +
                  campaign.scoreTotals.promoterCount,
                acc.scoreTotals.detractorCount +
                  campaign.scoreTotals.detractorCount,
                acc.scoreTotals.neutralCount +
                  campaign.scoreTotals.neutralCount,
              ),
              isAggregated: true,
            },
          }

          return result
        })

      latestCampaign = {
        ...aggregatedCampaigns,
      }

      latestCampaign['combinedIds'] = campaignsList.map(
        (campaign) => campaign.id,
      )
    }
  }
  return npsCampaigns.length > 0 ? (
    <NpsInsights
      npsCampaigns={npsCampaigns}
      latestCampaign={latestCampaign}
      subjects={subjects}
      assessors={assessors}
      userMode={!!userMode}
      measurements={measurements}
      filterAssessorIds={assessorIds}
      filterSubjectIds={subjectIds}
    />
  ) : (
    <EmptyData
      title={'No NPS Insights Found'}
      description={
        'There are no insights for the filters you have selected. Try changing your filters to find Insights'
      }
    />
  )
}
