import React from 'react'

import { TrophyIcon } from '@heroicons/react/24/outline'
import { CardContent } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { useBoolean } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'
import NpsLeaderboardCell from 'src/components/Nps/NpsLeaderboardCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

const NpsTopSubjects = ({ latestCampaign, assessors }) => {
  const showLeaderboard = useBoolean(false)

  const { trackEvent } = useAnalytics()

  return (
    <>
      <Card variant="outlined" sx={{ height: '250px', minWidth: '283px' }}>
        <CardHeader
          className={'!pb-0'}
          title={
            <h2 className={'text-sm leading-5 font-semibold'}>
              Latest Top Performers
            </h2>
          }
          action={
            <Button
              variant={'outlined'}
              startIcon={<TrophyIcon className={'w-4 h-4'} />}
              className={'!h-8 !px-2 !py-1'}
              onClick={() => {
                showLeaderboard.toggle()
                trackEvent('NPS', 'Click on View Leaderboard')
              }}
            >
              View Leaderboard
            </Button>
          }
        />
        <CardContent className={'overflow-y-hidden !p-0 !px-4'}>
          <NpsLeaderboardCell
            campaignIds={latestCampaign.combinedIds || latestCampaign.id}
            assessorIds={assessors}
            showLeaderboard={showLeaderboard}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default NpsTopSubjects
