import React, { type FC } from 'react'

import { Grid2 as Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import type { NpsInsightDataQuery } from 'types/graphql'

import Accordion from 'src/components/Library/Accordion/Accordion'
import NpsGuage from 'src/components/Nps/NpsGuage/NpsGuage'
import NpsScoreChart from 'src/components/Nps/NpsScoreChart/NpsScoreChart'
import NpsScoreDistribution from 'src/components/Nps/NpsScoreDistribution/NpsScoreDistribution'
import {
  CampaignSummary,
  CampaignTitle,
} from 'src/components/Nps/NpsSettings/NpsCampaignView/NpsCampaignView'
import NpsResultsCell from 'src/components/Nps/NpsSettings/NpsResultsCell'
import NpsTopSubjects from 'src/components/Nps/NpsTopSubjects/NpsTopSubjects'

type Props = {
  npsCampaigns: NpsInsightDataQuery['npsCampaigns']
  latestCampaign: NpsInsightDataQuery['npsCampaigns'][0]['latestCampaign']
  subjects: NpsInsightDataQuery['subjects']
  userMode: boolean
  assessors: NpsInsightDataQuery['assessors']
  measurements: NpsInsightDataQuery['measurements']
  filterAssessorIds: number[]
  filterSubjectIds: number[]
}

const NpsInsights: FC<Props> = ({
  npsCampaigns,
  latestCampaign,
  subjects,
  userMode = false,
  assessors,
  measurements,
  filterAssessorIds,
  filterSubjectIds,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 4 }}>
        <NpsGuage {...latestCampaign.scoreTotals} />
      </Grid>
      <Grid size={{ xs: userMode ? 8 : 4 }}>
        <NpsScoreDistribution
          {...latestCampaign.scoreTotals}
          userMode={userMode}
          surveySentCount={latestCampaign.surveySentCount}
          filtersUsed={filterAssessorIds || filterSubjectIds}
        />
      </Grid>
      {!userMode && (
        <Grid size={{ xs: 4 }}>
          <NpsTopSubjects
            latestCampaign={latestCampaign}
            assessors={filterAssessorIds}
          />
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <NpsScoreChart
          campaigns={npsCampaigns}
          measurements={measurements}
          assessorIds={filterAssessorIds}
          subjectIds={filterSubjectIds}
          assessors={assessors}
        />
      </Grid>
      {!userMode && (
        <Grid size={{ xs: 12 }}>
          <Stack spacing={2}>
            {npsCampaigns.map((campaign) => (
              <Accordion
                key={campaign.id}
                title={<CampaignTitle campaign={campaign} />}
                summary={<CampaignSummary campaign={campaign} />}
                TransitionProps={{ unmountOnExit: true }}
                GATrackerCategory="NPS"
              >
                <NpsResultsCell
                  readOnly={true}
                  id={campaign.id}
                  subjects={subjects}
                  assessors={assessors}
                  createNpsSubject={() => {}}
                />
              </Accordion>
            ))}
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}

export default NpsInsights
