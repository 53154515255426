import React, { useEffect, useState } from 'react'

import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { calculateNpsScore } from 'api/src/common/utils'
import type { ChartOptions } from 'chart.js'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'
import { groupBy } from 'ramda'
import { Chart } from 'react-chartjs-2'
import { useBoolean } from 'usehooks-ts'

import CustomDropdown from 'src/components/Library/CustomDropdown/CustomDropdown'
import Switch from 'src/components/Library/Switch/Switch'
import ToggleButton from 'src/components/Library/ToggleButton/ToggleButton'
import NpsPerformerGraphCell from 'src/components/Nps/NpsPerformerGraphCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
)
const NpsScoreChart = ({
  campaigns,
  measurements,
  assessorIds,
  subjectIds,
  assessors,
}) => {
  const subjectGraphVisible = useBoolean(false)
  const campaignIds = campaigns.map((campaign) => campaign.id)
  const labels = campaigns
    .map((campaign) => dayjs(campaign.campaignDate).format('DD/MM/YYYY'))
    .reverse()
    // remove duplicates
    .filter(
      (date: string, index: number, self: string[]) =>
        self.indexOf(date) === index,
    )

  const [datasets, setDatasets] = useState([])
  const groupAssessors = useBoolean(false)
  const groupMeasurements = useBoolean(false)
  const [options, setOptions] = useState<ChartOptions>({
    plugins: {
      legend: {
        display: true,
        onClick: () => {},
      },
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 10,
      },
    },
    scales: {
      y: {
        afterDataLimits: (scale) => {
          scale.max = 110
          scale.min = -110
        },
        min: -100,
        max: 100,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  })

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    displayedDataset()
  }, [groupMeasurements.value, groupAssessors.value, campaigns])

  const selectColor = (number) => {
    const hue = number * 137.508 // use golden angle approximation
    return `hsl(${hue},50%,45%)`
  }

  const displayedDataset = () => {
    if (groupAssessors.value && groupMeasurements.value) {
      setDatasets([getAllCampaignsLine()])
    } else if (groupAssessors.value) {
      setDatasets(getGroupedAssessorLines())
    } else if (groupMeasurements.value) {
      setDatasets(getGroupMeasurementLines())
    } else {
      setDatasets(getIndividualAssessorLines())
    }
  }

  const getGroupedAssessorLines = () => {
    // combine all assessor scores into one array
    const assessorData = campaigns
      .map((campaign) => {
        return campaign.assessors.map((assessor) => {
          return {
            ...assessor,
            campaignDate: campaign.campaignDate,
          }
        })
      })
      .flat()
    // create a list of assessorIds
    const assessorIds = assessorData
      .map((assessor) => assessor.assessorId)
      .filter((value, index, self) => self.indexOf(value) === index)

    return assessorIds.map((assessorId) => {
      return {
        type: 'line' as const,
        label: `${assessors.find((a) => a.id === assessorId)?.name}-Score`,
        data: labels.map((label) => {
          const selectedAssessor = assessorData.find((assessor) => {
            return (
              dayjs(assessor.campaignDate).format('DD/MM/YYYY') === label &&
              assessor.assessorId === assessorId
            )
          })

          return selectedAssessor ? selectedAssessor.npsScore : null
        }),
        spanGaps: true,
        borderColor: selectColor(assessorId),
        borderWidth: 2,
        fill: false,
      }
    })
  }

  const getIndividualAssessorLines = () => {
    //group campaigns by measurementId
    const groupedCampaigns = groupBy(
      (campaign: any) => campaign.measurementId,
      campaigns,
    )
    const lines = []
    for (const [key, measurment] of Object.entries(groupedCampaigns)) {
      //create a list of assessorIds in the measurement within the campaign
      const assessorIds = measurment
        .map((campaign: any) =>
          campaign.assessors.map((assessor) => assessor.assessorId),
        )
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)

      lines.push(
        assessorIds.map((assessorId) => {
          return {
            type: 'line' as const,
            label: `${measurements.find((m) => m.id === parseInt(key))?.name}-${
              assessors.find((a) => a.id === assessorId)?.name
            }-Score`,
            data: labels.map((label) => {
              const selectedCampaign = measurment.find(
                (campaign: any) =>
                  dayjs(campaign.campaignDate).format('DD/MM/YYYY') === label,
              )

              const selectedAssessor = selectedCampaign?.assessors.find(
                (selectAssessor) => selectAssessor.assessorId === assessorId,
              )

              return selectedCampaign && selectedAssessor
                ? selectedAssessor.npsScore
                : null
            }),
            spanGaps: true,
            borderColor: selectColor(parseInt(key) + assessorId),
            borderWidth: 2,
            fill: false,
          }
        }),
      )
    }
    return lines.flat()
  }

  const getAllCampaignsLine = () => {
    const combinedCampaigns = campaigns.reduce((acc, campaign) => {
      const matchingCampaignIndex = acc.findIndex(
        (c) =>
          dayjs(c.campaignDate).format('DD/MM/YYYY') ===
          dayjs(campaign.campaignDate).format('DD/MM/YYYY'),
      )

      if (matchingCampaignIndex !== -1) {
        const matchingCampaign = acc[matchingCampaignIndex]
        const updatedCampaign = {
          ...matchingCampaign,
          scoreTotals: {
            ...matchingCampaign.scoreTotals,
            neutralCount:
              matchingCampaign.scoreTotals.neutralCount +
              campaign.scoreTotals.neutralCount,
            promoterCount:
              matchingCampaign.scoreTotals.promoterCount +
              campaign.scoreTotals.promoterCount,
            detractorCount:
              matchingCampaign.scoreTotals.detractorCount +
              campaign.scoreTotals.detractorCount,
            npsScore: calculateNpsScore(
              matchingCampaign.scoreTotals.promoterCount +
                campaign.scoreTotals.promoterCount,
              matchingCampaign.scoreTotals.detractorCount +
                campaign.scoreTotals.detractorCount,
              matchingCampaign.scoreTotals.neutralCount +
                campaign.scoreTotals.neutralCount,
            ),
          },
        }

        return [
          ...acc.slice(0, matchingCampaignIndex),
          updatedCampaign,
          ...acc.slice(matchingCampaignIndex + 1),
        ]
      } else {
        return [...acc, campaign]
      }
    }, [])

    return {
      type: 'line' as const,
      label: 'Score',
      data: labels.map((label) => {
        const selectedCampaign = combinedCampaigns.find(
          (campaign) =>
            dayjs(campaign.campaignDate).format('DD/MM/YYYY') === label,
        )
        return selectedCampaign?.scoreTotals.npsScore || null
      }),
      borderColor: '#4f46e5',
      borderWidth: 2,
      fill: false,
      spanGaps: true,
      order: 1,
    }
  }

  const getGroupMeasurementLines = () => {
    // create lines for campaigns based on measurement id
    const measurementIds = [
      ...new Set(campaigns.map((campaign) => campaign.measurementId)),
    ]
    const lines = measurementIds.map((measurementId) => {
      const matchingCampaigns = campaigns.filter(
        (campaign) => campaign.measurementId === measurementId,
      )

      return {
        type: 'line' as const,
        label:
          measurements.find((m) => m.id === measurementId)?.name + '-Score',
        data: labels.map((label) => {
          const campaign = matchingCampaigns.find(
            (campaign) =>
              dayjs(campaign.campaignDate).format('DD/MM/YYYY') === label,
          )
          return campaign ? campaign.scoreTotals.npsScore : null
        }),
        spanGaps: true,
        borderColor: selectColor(measurementId),
        borderWidth: 2,
        fill: false,
        order: 1,
      }
    })
    return lines
  }

  return (
    <Card variant="outlined" sx={{ height: '480px' }}>
      <CardHeader
        className={'!pb-0'}
        title={
          <Stack direction={'row'} spacing={2} alignItems="center">
            <h2 className={'text-sm leading-5 font-semibold'}>
              {subjectGraphVisible.value ? 'Performer Results' : 'NPS History'}
            </h2>
            {!subjectGraphVisible.value && (
              <CustomDropdown
                buttonTitle={'Group By'}
                buttonProps={{
                  fullWidth: false,
                  endIcon: <AdjustmentsHorizontalIcon className={'w-4 h-4'} />,
                  size: 'small',
                  variant: 'outlined',
                }}
                GATrackerCategory="NPS"
              >
                <Stack
                  direction={'row'}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <div className={'w-20'}>Assessors</div>
                  <Switch
                    checked={groupAssessors.value}
                    onChange={() => {
                      groupAssessors.toggle()
                      trackEvent('NPS', 'group by assessors', {
                        toggleVal: !groupAssessors.value,
                      })
                    }}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <div className={'w-20'}>Categories</div>
                  <Switch
                    checked={groupMeasurements.value}
                    onChange={() => {
                      groupMeasurements.toggle()
                      trackEvent('NPS', 'group by measurement', {
                        toggleVal: !groupMeasurements.value,
                      })
                    }}
                  />
                </Stack>
              </CustomDropdown>
            )}
          </Stack>
        }
        action={
          <div className={'w-96'}>
            <ToggleButton
              buttonProps={{
                leftButton: {
                  leftText: 'NPS History',
                  leftOnClick: () => {
                    subjectGraphVisible.setFalse()
                    trackEvent('NPS', 'Click on NPS History')
                  },
                },
                rightButton: {
                  rightText: 'Performer Results',
                  rightOnClick: () => {
                    subjectGraphVisible.setTrue()
                    trackEvent('NPS', 'Click on Performer Results')
                  },
                },
              }}
            />
          </div>
        }
      />
      <CardContent
        className={'flex justify-center h-[calc(100%-2rem)] w-full !pt-0 !mb-1'}
      >
        {subjectGraphVisible.value ? (
          <NpsPerformerGraphCell
            campaignIds={campaignIds}
            assessorIds={assessorIds}
            subjectIds={subjectIds}
          />
        ) : (
          <Chart type={'line'} data={{ labels, datasets }} options={options} />
        )}
      </CardContent>
    </Card>
  )
}

export default NpsScoreChart
