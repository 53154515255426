import React from 'react'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindNpsUserGraphQuery(
    $assessorIds: [Int!]
    $campaignIds: [Int!]
    $subjectIds: [Int!]
  ) {
    leaderboard: npsLeaderboard(
      assessorIds: $assessorIds
      campaignIds: $campaignIds
      subjectIds: $subjectIds
    ) {
      subject {
        id
        name
        membership {
          id
          user {
            id
            name
            avatarUrl
          }
        }
      }
      score
      promoterCount
      detractorCount
      neutralCount
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ leaderboard }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const labels = leaderboard.map(
    ({ subject }) => subject.membership?.user.name || subject.name,
  )

  const data = {
    labels,
    // labels: ['Promoters', 'Passives', 'Detractors'],
    datasets: [
      {
        label: 'Promoters',
        data: leaderboard.map(({ promoterCount }) => promoterCount),
        backgroundColor: '#22c55e',
        order: 1,
      },
      {
        label: 'Passives',
        data: leaderboard.map(({ neutralCount }) => neutralCount),
        backgroundColor: '#eab308',
        order: 2,
      },
      {
        label: 'Detractors',
        data: leaderboard.map(({ detractorCount }) => detractorCount),
        backgroundColor: '#ef4444',
        order: 3,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 10,
        },
      },
    },
  }

  return <Bar options={options} data={data} />
}
