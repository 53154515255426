import React, { useState } from 'react'

import { TextField, Box, Grid2 as Grid } from '@mui/material'
import { NpsCampaign } from 'types/graphql'

import type { CellFailureProps } from '@redwoodjs/web'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import useAnalytics from 'src/lib/hooks/useAnalytics'

export const QUERY = gql`
  query NpsInsightsFiltersQuery {
    subjects: npsSubjects {
      id
      name
      position
      membership {
        id
        user {
          id
          name
        }
      }
    }
    assessors: npsAssessors {
      id
      name
    }
    campaigns: npsCampaigns {
      id
      name
      measurementId
    }
    measurements: npsMeasurements {
      id
      name
    }
    membershipGroups {
      id
      name
      memberships {
        id
        membership {
          npsSubject {
            id
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <></>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  subjects,
  assessors,
  campaigns,
  measurements,
  setMeasurementIds,
  setAssessorIds,
  setSubjectIds,
  setCampaignIds,
  userMode = false,
  membershipGroups,
}) => {
  const [measurementValue, setMeasurementValue] = useState([])
  const [measurmentText, setMeasurmentText] = useState('')
  const [campaignValue, setCampaignValue] = useState([])
  const [campaignText, setCampaignText] = useState('')
  const [assessorValue, setAssessorValue] = useState([])
  const [assessorText, setAssessorText] = useState('')
  const [subjectValue, setSubjectValue] = useState([])
  const [subjectText, setSubjectText] = useState('')

  const { trackEvent } = useAnalytics()
  const subjectOptions = [...subjects, ...membershipGroups]

  const handleSubjectChange = (_event, newValue) => {
    if (newValue.length === 0) {
      setSubjectIds(null)
    } else {
      //filter out groups
      const filteredGroups = newValue.filter(
        (v) => v.__typename === 'MembershipGroup',
      )
      const groupSubjectIds = filteredGroups
        .map((group) => {
          return group.memberships
            .filter((member) => member.membership.npsSubject.length > 0)
            .map((member) => {
              return member.membership.npsSubject[0].id
            })
            .flat()
        })
        .flat()
      //filter out subjects and reduce to just ids
      const filteredSubjects = newValue
        .filter((v) => v.__typename === 'NpsSubject')
        .map((v) => v.id)

      //join the two arrays and remove duplicate numbers
      const newSubjectIds = [...groupSubjectIds, ...filteredSubjects].filter(
        (v, i, a) => a.indexOf(v) === i,
      )
      setSubjectIds(newSubjectIds)
    }
    setSubjectValue(newValue)
    trackEvent('NPS', 'filter by performer')
  }

  const sortCampaignMeasurements = (a, b) => {
    const aId = a.measurementId || a.id
    const bId = b.measurementId || b.id
    return aId - bId
  }

  return (
    <Grid container spacing={2} direction={'row-reverse'}>
      {!userMode && (
        <>
          <Grid size={{ xs: 3 }}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              size={'small'}
              value={assessorValue}
              filterSelectedOptions
              onChange={(_event, newValue) => {
                if (newValue.length === 0) {
                  setAssessorIds(null)
                } else {
                  setAssessorIds(newValue.map((v) => v.id))
                }
                setAssessorValue(newValue)
                trackEvent('NPS', 'filter by assessors')
              }}
              limitTags={2}
              inputValue={assessorText}
              onInputChange={(_event, newInputValue) => {
                setAssessorText(newInputValue)
              }}
              options={assessors || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label={'Assessors'} />
              )}
            />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              size={'small'}
              value={subjectValue}
              filterSelectedOptions
              onChange={handleSubjectChange}
              limitTags={2}
              inputValue={subjectText}
              onInputChange={(_event, newInputValue) => {
                setSubjectText(newInputValue)
              }}
              options={subjectOptions || []}
              getOptionLabel={(option) =>
                option.name || option?.membership?.user.name
              }
              renderInput={(params) => (
                <TextField {...params} label={'Performers/Groups'} />
              )}
            />
          </Grid>
        </>
      )}
      <Grid size={{ xs: 3 }}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          value={campaignValue}
          filterSelectedOptions
          onChange={(_event, newValue) => {
            if (newValue.length === 0) {
              setCampaignIds(null)
              setMeasurementIds(null)
            } else {
              const filteredMeasurements = newValue.filter(
                (v) => v.__typename === 'NpsMeasurement',
              )
              if (filteredMeasurements.length === 0) {
                setMeasurementIds(null)
              } else {
                const newMeasurementIds = filteredMeasurements.map((v) => v.id)
                setMeasurementIds(newMeasurementIds)
              }
              const filteredCampaigns = newValue.filter(
                (v) => v.__typename === 'NpsCampaign',
              )
              if (filteredCampaigns.length === 0) {
                setCampaignIds(null)
              } else {
                const newCampaignIds = filteredCampaigns.map((v) => v.id)

                setCampaignIds(newCampaignIds)
              }
            }
            setCampaignValue(newValue)
            trackEvent('NPS', 'filter by campaign/measurement')
          }}
          limitTags={2}
          inputValue={campaignText}
          onInputChange={(_event, newInputValue) => {
            setCampaignText(newInputValue)
          }}
          size={'small'}
          options={
            [...measurements, ...campaigns].sort(sortCampaignMeasurements) || []
          }
          getOptionLabel={(option: NpsCampaign) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <p
                className={
                  option.__typename === 'NpsCampaign'
                    ? 'ml-4'
                    : 'ml-1 font-bold'
                }
              >
                {option.name}
              </p>
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label={'Campaigns/Categories'} />
          )}
        />
      </Grid>
    </Grid>
  )
}
