import React from 'react'

import { InboxArrowDownIcon } from '@heroicons/react/24/solid'
import { Chip, Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import { convertNumberToShortForm } from 'src/Util'

const NpsSubjectCard = ({ result, index }) => {
  return (
    <Card key={result.subject.id} elevation={0} className={'rounded-lg'}>
      <CardHeader
        sx={{
          action: {
            margin: 'auto',
          },
        }}
        avatar={
          <Avatar
            src={result.subject?.membership?.user?.avatarUrl}
            className={`${index + 1 === 1 && 'ring-amber-400'} ${
              index + 1 === 2 && 'ring-zinc-500'
            } ${index + 1 === 3 && 'ring-amber-700'} ring-4`}
          />
        }
        title={
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
          >
            {result.subject?.name || result.subject?.membership?.user?.name}
            <Stack direction={'row'} spacing={3} alignItems="center">
              <Tooltip title="Responses Recieved">
                <Chip
                  className={'!w-24'}
                  icon={
                    <InboxArrowDownIcon className="h-5 w-5 fill-gray-700" />
                  }
                  size={'small'}
                  label={convertNumberToShortForm(
                    result.promoterCount +
                      result.neutralCount +
                      result.detractorCount,
                  )}
                />
              </Tooltip>
              <span
                className={'text-lg font-normal leading-none text-indigo-500'}
              >
                {result.score}
              </span>
            </Stack>
          </Stack>
        }
      />
    </Card>
  )
}

export default NpsSubjectCard
